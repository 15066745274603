import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Iphone15Pro from './magicui/iphone-15-pro'; // Import the Iphone15Pro component
import heroImage from './img/hero.png';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
        marginBottom: { xs: '40vh', sm: '60vh' }, // Increase bottom margin
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 8, sm: 10 },
          pb: { xs: 14, sm: 20 }, // Increase bottom padding
          position: 'relative',
          overflow: 'visible',
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: '100%', mb: { xs: 6, sm: 10 } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              marginTop: { xs: 4, sm: 6, md: 8 }, // Add responsive top margin
            }}
          >
            Your&nbsp;personal&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              Wardrobe
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            Explore your first, very own virtual wardrobe - where you can decide what happens without any disturbances.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <Button variant="contained" color="primary">
              Start now
            </Button>
          </Stack>
          <Typography variant="caption" sx={{ textAlign: 'center' }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
        
        <Box 
          sx={{ 
            position: 'absolute',
            top: '75%', // Position below the content
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: { xs: '280px', sm: '400px' },
            zIndex: 1,
          }}
        >
          <div style={{ width: '100%', paddingBottom: '200%', position: 'relative' }}>
            <Iphone15Pro 
              src={heroImage} 
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '80%',
                objectFit: 'contain',
              }}
            />
          </div>
        </Box>
      </Container>
    </Box>
  );
}
