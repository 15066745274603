
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./Css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import LandingPage from "./LandingPage/LandingPage";

function App() {

  return (
    <div className="App">
      <Router>
        <Container fluid>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="https://wardrobe-devbuild.netlify.app/"
            />
          </Routes>
        </Container>
      </Router>
    </div>
  );
}

export default App;