import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Button, IconButton, Container, Box, Menu, MenuItem as MenuItemMUI, Drawer, Divider, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ReactComponent as Logo } from './assets/logo.svg';
import { useTranslation } from 'react-i18next';


const AppAppBar = ({ mode }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  //eslint-disable-next-line
  const [user, setUser] = useState(null); // Assuming you'll handle user state here
  //eslint-disable-next-line
  const [userName, setUserName] = useState(''); // Assuming you'll handle user name state here
  const [showUsername, setShowUsername] = useState(false);

  useEffect(() => {
    if (user) {
      const interval = setInterval(() => {
        setShowUsername((prev) => !prev);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [user]);

  const toggleDrawer = (newOpen) => () => setOpen(newOpen);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const offset = 128;
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({ top: targetScroll, behavior: 'smooth' });
      setOpen(false);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <AppBar position="fixed" sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}>
        <Container maxWidth="lg">
          <Toolbar variant="regular" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexShrink: 0, borderRadius: '999px', backdropFilter: 'blur(24px)', maxHeight: 40, border: '1px solid', borderColor: 'divider', bgcolor: 'hsla(220, 60%, 99%, 0.6)', boxShadow: '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)' }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 2 }}>
              <Logo style={{ height: 70, width: 70, }} />
              <Typography sx={{ marginLeft: 2, color: 'black', fontFamily: "Nothing You Could Do", fontWeight: 400, fontStyle: 'normal', fontSize: 24 }}>
                {user ? (showUsername ? `I'm ${userName}` : "I'm organized") : "I'm organized"}
              </Typography>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2, alignItems: 'center' }}>
              <Button variant="text" color="info" size="small" onClick={() => scrollToSection('features')}>{t("features")}</Button>
              <Button variant="text" color="info" size="small" onClick={() => scrollToSection('testimonials')}>{t("testimonials")}</Button>
              <Button variant="text" color="info" size="small" onClick={() => scrollToSection('highlights')}>{t("highlights")}</Button>
              <Button variant="text" color="info" size="small" onClick={() => scrollToSection('pricing')}>{t("pricing")}</Button>
              <Button variant="text" color="info" size="small" onClick={() => scrollToSection('faq')} sx={{ minWidth: 0 }}>{t("FAQ")}</Button>
              <Button color="primary" variant="text" size="small" href="https://app.imorganized.online">{t("gotowardrobe")}</Button>
              {user && (
                <>
                  <Button color="primary" variant="text" size="small" onClick={handleMenuOpen}>{userName}</Button>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItemMUI onClick={() => alert('Update Username')}>{t("updateusername")}</MenuItemMUI>
                    <MenuItemMUI onClick={() => alert('Sign Out')}>{t("signOut")}</MenuItemMUI>
                  </Menu>
                </>
              )}
            </Box>
            <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
              <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
                <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <IconButton onClick={toggleDrawer(false)}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>
                  <Divider sx={{ my: 3 }} />
                  <MenuItemMUI onClick={() => scrollToSection('features')}>{t("features")}</MenuItemMUI>
                  <MenuItemMUI onClick={() => scrollToSection('testimonials')}>{t("testimonials")}</MenuItemMUI>
                  <MenuItemMUI onClick={() => scrollToSection('highlights')}>{t("highlights")}</MenuItemMUI>
                  <MenuItemMUI onClick={() => scrollToSection('pricing')}>{t("pricing")}</MenuItemMUI>
                  <MenuItemMUI onClick={() => scrollToSection('faq')}>{t("FAQ")}</MenuItemMUI>
                  <MenuItemMUI>
                    <Button color="primary" variant="contained" fullWidth href="https://app.imorganized.online">{t("gotowardrobe")}</Button>
                  </MenuItemMUI>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
};

export default AppAppBar;